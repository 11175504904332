<template>
	<div class="content-wrap">
		<!--研究型病房入驻情况报表-->
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset" :isShowReset="false">
			<CtmsSearchBoxItem :span="1" label="项目类型">
				<Select
					v-model="searchParams.projectTypeId"
					:transfer="true"
					filterable
					placeholder="请选择项目类型"
				>
					<Option
						:value="item.id"
						v-for="item in projectTypeList"
						:key="item.id"
						>{{ item.typeName }}
					</Option>
				</Select>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="创建日期">
				<DatePicker
					type="daterange"
					placeholder="请选择"
					:transfer="true"
					style="width: 100%"
					format="yyyy-MM-dd"
					:value="times"
					@on-change="v => (times = v)"
				></DatePicker>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<!-- <CtmsAction>
			<Button @click="exportData">导出研究型病房入驻情况</Button>
		</CtmsAction> -->
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="data"
      		:page="page"
			ref="table"
		>
		</CtmsDataGrid>
	</div>
</template>

<script>

import api from "@/api/report/report"
import fundsApi from "@/api/project/funds"
import projectApi from "@/api/project/project"
import { mapState } from "vuex"

const { apiGetDeparts } = fundsApi
const { apiGetProjTypeList } = projectApi
const { apiGetResearchWardSettledInfo } = api

export default {
	name: "WardCheckIn",
	data() {
		return {
			
			projectTypeList: [],
			loading: false,
			entry: "",
			updateId: "",
			// 科室
			departList: [],
			// 项目基本信息
			projectInfo: {},
			times: [],
			searchParams: {
				projectTypeId: 10011,
				createTimeStart: this.times ? this.times[0] : "",
				createTimeEnd: this.times ? this.times[1] : ""
			},
			oldSearchParams: {
				projectTypeId: 10011,
				createTimeStart: this.times ? this.times[0] : "",
				createTimeEnd: this.times ? this.times[1] : ""
			},
			data: [],
			columns: [
				{
					title: "项目名称",
					key: "projectName",
					minWidth: 250
				},
				{
					title: "项目等级",
					key: "projectLevel",
					minWidth: 140,
				},
				{
					title: "访视周期",
					key: "visitCycle",
					minWidth: 130
				},
				{
					title: "生物样本拟采集类型",
					key: "stType",
					minWidth: 200
				},
				{
					title: "项目进度",
					key: "projectSchedule",
					minWidth: 180
				},
				{
					title: "责任医生",
					key: "primarySub",
					minWidth: 150
				},
				{
					title: "责任护士",
					key: "primaryNurse",
					minWidth: 130
				},
				{
					title: "CRU对接人员（IIT项目填写）",
					key: "cruContactPerson",
					minWidth: 220
				},
				{
					title: "质控对接人（IST项目填写）",
					key: "qcContactPerson",
					minWidth: 220
				},
				{
					title: "项目累计入组人数",
					key: "groupAmount",
					minWidth: 150
				},
				{
					title: "病房内累计访视人次",
					key: "visitAmount",
					minWidth: 200
				},
				{
					title: "累计住院天数",
					key: "hospDay",
					minWidth: 120
				},
				{
					title: "生物样本累计采集例次",
					key: "stAmount",
					minWidth: 200
				},
				{
					title: "本院SAE例次",
					key: "saeAmount",
					minWidth: 150
				},
				{
					title: "已质控次数",
					key: "pvAmount",
					minWidth: 140,
					render: (h, params) => {
						return h('span', params.row.pvAmount == 0 ? '' : params.row.pvAmount)
					}
				},
				{
					title: "上次质控时间",
					key: "pvDate",
					minWidth: 140
				},
				{
					title: "上报方案违背/偏离例次",
					key: "pdPvNumber",
					minWidth: 200
				}
			],
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			}
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 140
		})
	},
	mounted() {
		this.getPublicData()
		this.getProjectTypeList()
	},
	methods: {
		// 项目类型
		getProjectTypeList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetProjTypeList()
				if (res) {
					this.projectTypeList = res.data
					this.searchParams.projectTypeId = this.projectTypeList.length
						? this.projectTypeList[1].id
						: ""
					this.oldSearchParams.projectTypeId = this.searchParams.projectTypeId
					this.initData()
				} else {
					this.loading = false
				}
			})
		},
		getPublicData() {
			// 获取科室
			this.$asyncDo(async () => {
				const res = await apiGetDeparts()
				if (res) {
					this.departList = res.data || []
				}
			})
		},
		initData() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetResearchWardSettledInfo({
					...this.oldSearchParams,
					pageNum: this.page.current,
					pageSize: this.page.pageSize,
				})
				if (res) {
					this.data = res.data.records || []
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},
		// exportData() {
		// 	/*
		// 	 * type 费用类型：IST、申办方发起的研究IST IIT、研究者发起的研究IIT
		// 	 * createTimeStart 创建时间-开始
		// 	 * createTimeEnd 创建时间-结束
		// 	 * */
		// 	const type = this.searchParams.type || ""
		// 	const start = this.searchParams.createTimeStart || ""
		// 	const end = this.searchParams.createTimeEnd || ""
			
		// 	const url = `${this.$baseUrl}/report/outlay/export?classification=1&type=${type}&createTimeStart=${start}&createTimeEnd=${end}&approvedStatus=${approvedStatus}&token=${this.$store.state.user.token}`
		// 	window.open(url)
		// },
		// 重置
		reset() {
			this.times = []
			this.searchParams.projectTypeId = ""
			this.searchHandle()
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initData()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initData()
			})
		},
		searchHandle() {
			this.searchParams.createTimeStart = this.times.length
				? this.times[0]
				: ""
			this.searchParams.createTimeEnd = this.times.length ? this.times[1] : ""
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initData()
			})
		}
	}
}
</script>
