import request from "@/utils/request"

const moduleName = "/common-form"

const makeRequest = async (url, options) => {
	try {
		return await request(url, options)
	} catch (error) {
		console.error("Error making request:", error)
		throw new Error("Failed to make request")
	}
}

const validateParams = params => {
	if (!params) {
		throw new Error("Missing required parameters")
	}
}

export default {
	/* 研究成果模块 查询列表 */
	// 获取通用表单列表 分页
	apiGetPage: async data => {
		validateParams(data)
		return await makeRequest(`${moduleName}/research-list`, {
			method: "get",
			params: data
		})
	},
	// 根据表单模板id 获取通用表单列表表头 templateId
	getListHeader: async data => {
		validateParams(data)
		return await makeRequest(`${moduleName}/research-header`, {
			method: "get",
			params: data
		})
	},
	// 根据表单模板id 获取通用表单列表 templateId
	getTypeList: async data => {
		validateParams(data)
		return await makeRequest(`${moduleName}/research-list-report`, {
			method: "get",
			params: data
		})
	},
	// 按项目查询研究成果
	getResearchListProject: data =>
		request(`${moduleName}/research-project`, {
			method: "get",
			params: data
		}),
	// 按表单类型查询研究成果
	getTypeListProject: data =>
		request(`${moduleName}/research-type`, {
			method: "get",
			params: data
		}),

	/* 系统设置--研究成果表单配置 */
	// 获取通用表单配置列表 研究成果
	apiGetFormConfigList: () =>
		request(`${moduleName}/config`, {
			method: "get"
		}),
	// 新增通用表单配置 研究成果
	apiAddFormType: data =>
		request(`${moduleName}/config`, {
			method: "post",
			body: data
		}),
	// 修改通用表单配置 研究成果
	apiEditFormType: data =>
		request(`${moduleName}/config`, {
			method: "put",
			body: data
		}),
	// 删除通用表单配置 研究成果
	apiDelFormType: formId =>
		request(`${moduleName}/config/${formId}`, {
			method: "delete"
		}),
	// 查询通用表单组件配置详情
	apiGetFormConfig: formId =>
		request(`${moduleName}/config-detail/${formId}`, {
			method: "get"
		}),
	// 保存通用表单組件配置详情（增加和修改）
	apiSaveFormConfig: data =>
		request(`${moduleName}/config-detail/save`, {
			method: "post",
			body: data
		}),
	/* 进入项目：研究成果填写 */
	// 查询项目研究成果信息
	getProjectAchieveForm: data =>
		request(`${moduleName}/project/${data.projectId}`, {
			method: "get"
		}),
	// 保存项目研究成果表单（增加和修改）
	apiSaveProjectAchieveForm: data =>
		request(`${moduleName}/project`, {
			method: "post",
			body: data
		}),
  // 创建项目研究成果草稿
  apiResResultDraft: data =>
    request(`/project/${data.projectId}/res-result/draft`, {
      method: "post",
      params: data
    }),
  // 保存项目研究成果
  apiResResultSave: data =>
    request(`/project/${data.projectId}/res-result/save`, {
      method: "post",
      body: data
    }),
	// 查询研究成果列表
	getResResultList: data =>
		request(`/project/${data.projectId}/res-result`, {
			method: "get"
		}),
	// 删除项目研究成果
	getDelResResResult: data =>
		request(`/project/${data.projectId}/res-result/${data.formDataId}`, {
			method: "delete"
		}),
	// 发布项目研究成果状态
	apiResResultPublish: data =>
		request(`/project/${data.projectId}/res-result/${data.formDataId}/publish`, {
      method: "post",
    }),
  // 获取通用表单配置详情
  getConfigDetailForm: data =>
    request(`/common-form/config-detail/${data.formId}`, {
      method: "get"
    }),


}
