<template>
	<div class="content-wrap">
		<Spin fix v-if="loading"></Spin>
		<!--操作按钮-->
		<CtmsAction>
			<Button
				v-if="systemActionPermissions.indexOf('btn_research_config_add') > -1"
				@click="handleAddShow"
				>新增</Button
			>
		</CtmsAction>

		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="false"
		></CtmsDataGrid>
		<!--新增研究成果类型-->
		<Modal
			v-model="addVisible"
			:title="title"
			:styles="{ top: '20%' }"
			:mask-closable="false"
			@on-cancel="cancel"
		>
			<Form
				ref="proTypeForm"
				:model="proTypeForm"
				v-if="addVisible"
				:rules="rules"
				:label-width="140"
			>
				<FormItem label="研究成果类型：" prop="typeName">
					<Input
						v-model.trim="proTypeForm.typeName"
						:maxlength="50"
						placeholder="请输入研究成果类型名称"
					></Input>
				</FormItem>
			</Form>
			<div slot="footer">
				<Button @click="cancel">取消</Button>
				<Button type="primary" :loading="loading" @click="ok">确定</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
import api from "@/api/achievement/achievement"
import { mapState } from "vuex"

const {
	apiGetFormConfigList,
	apiAddFormType,
	apiEditFormType,
	apiDelFormType
} = api

export default {
	name: "achieveFormList",
	data() {
		return {
			title: "新增研究成果类型",
			actionType: "add",
			listData: [],
			columns: [
				{ title: "研究成果类型", key: "name" },
				{
					title: "操作",
					key: "action",
					renderButton: params => {
						const actionList = [
							{
								label: "修改",
								on: {
									click: this.handleUpdateShow
								}
							},
							{
								label: "表单设计",
								on: {
									click: () => {
										this.$router.push({
											path: "/setting/achieve-form/config",
											query: {
												id: params.row.formId
											}
										})
									}
								}
							},
							{
								label: "删除",
								on: {
									click: this.handleDelete
								}
							}
						]
						if (
							this.systemActionPermissions.indexOf(
								"btn_research_config_add"
							) === -1
						) {
							actionList[0] = null
						}
						if (
							this.systemActionPermissions.indexOf(
								"btn_research_config_design"
							) === -1
						) {
							actionList[1] = null
						}
						if (
							this.systemActionPermissions.indexOf(
								"btn_research_config_del"
							) === -1
						) {
							actionList[2] = null
						}
						return actionList.filter(item => !!item)
					}
				}
			],
			loading: false,
			addVisible: false,
			proTypeForm: {
				typeName: ""
			},
			rules: {
				typeName: {
					required: true,
					message: "研究成果类型不能为空",
					trigger: "blur"
				}
			},
			updateId: "",
			viewId: ""
		}
	},
	created() {
		this.initList()
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 130
		}),
		...mapState("permission", ["systemActionPermissions"])
	},
	watch: {
		visible() {
			if (this.visible) {
				this.$refs.proTypeForm.resetFields()
				this.proTypeForm = {}
			}
		}
	},
	methods: {
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetFormConfigList()
				if (res) {
					this.listData = res.data
				}
				this.loading = false
			})
		},
		// 新增
		handleAddShow() {
			this.title = "新增研究成果类型"
			this.proTypeForm.typeName = ""
			this.updateId = ""
			this.actionType = "add"
			this.addVisible = true
		},
		// 修改
		handleUpdateShow({ row }) {
			this.proTypeForm.typeName = row.name
			this.title = "修改研究成果类型"
			this.updateId = row.formId
			this.actionType = "edit"
			this.addVisible = true
		},
		// 新增/修改研究成果类型
		ok() {
			this.$refs.proTypeForm.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						let res = false
						if (this.updateId) {
							res = await apiEditFormType({
								name: this.proTypeForm.typeName,
								formId: this.updateId
							})
						} else {
							// 新增
							res = await apiAddFormType({
								name: this.proTypeForm.typeName
							})
						}
						if (res) {
							this.$Message.success("操作成功")
							this.addVisible = false
							this.initList()
						}
						this.loading = false
					})
				}
			})
		},
		cancel() {
			this.addVisible = false
		},
		// 删除
		handleDelete({ row }) {
			this.$Modal.confirm({
				title: "确定删除该研究成果类型？",
				content: "提示：删除类型同时会删除表单内已经填写的所有成果内容！",
				onOk: async () => {
					this.loading = true
					let res = false
					res = await apiDelFormType(row.formId)
					if (res) {
						this.$Message.success("操作成功！")
						this.initList()
					}
					this.loading = false
				}
			})
		}
	}
}
</script>
